import React, { FC } from "react";
import * as styles from "./SingleWebinarForm.module.scss";
import Layout from "../../../components/Layout/Layout";
import { RiCloseFill } from "react-icons/ri";
import { Link, navigate } from "gatsby";
import { pathWithoutSpace } from "../../ReportsUiComponents/ReportsMainHelpers";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import { webinarFields } from "./SingleWebinarFormHelper";
import ContactInputForm from "../../Shared/ContactInputForm/ContactInputForm";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import { Buffer } from "buffer";

type Props = {
  pageContext: any;
  data: any;
};

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  dateOfRegistration?: Date;
};

const SingleWebinarForm: FC<Props> = ({ pageContext }) => {
  const { width } = UseWindowSize();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (data: any) => {
    if (!errors.email && !errors.firstName && !errors.lastName) {
      setTimeout(() => {
        reset({});
      }, 100);
      navigate("/webinars/thank-you");
    }
  };
  const webinarDate = pageContext.date;
  const today = new Date().toISOString();
  let buttonText = "";
  today > webinarDate
  ? (buttonText = "Register for recording")
  : (buttonText = "Register for event");
  return (
    <Layout location="/blog">
      <div className={styles.webinarFormContainer}>
        <div className={styles.webinarFormColumnLeft}>
          <h1 className="heading">
            <span>.</span>{buttonText}
          </h1>
          <p>{pageContext.title}</p>
        </div>
        <div className={styles.webinarFormColumnRight}>
          <Link to={`/webinars/${pathWithoutSpace(pageContext.title)}`}>
            <RiCloseFill
              color="#666666"
              size={28}
              className={styles.webinaFormClose}
            />
          </Link>

          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              id={"dateOfRegistration"}
              type="text"
              hidden={true}
              name={"dateOfRegistration"}
              value={Buffer.from(new Date().toISOString()).toString("base64")}
              defaultValue={Buffer.from(new Date().toISOString()).toString(
                "base64"
              )}
            />
            {webinarFields.map(field => {
              return (
                <div key={field.label} className={styles.singleWebinarInput}>
                  <ContactInputForm
                    name={field.name}
                    placeHolder={field.placeholder}
                    register={register}
                    isRequired={field.require}
                    labelInput={field.label}
                    wrapperClassName={
                      width > 800 ? styles.field : styles.fieldMobile
                    }
                  />
                </div>
              );
            })}
            <div>
              {errors.email || errors.firstName || errors.lastName ? (
                <span
                  style={{
                    color: "#ff3333",
                    fontFamily: "Poppins",
                    fontSize: "13px",
                  }}
                >
                  {errors.email?.message
                    ? "Please enter a valid email"
                    : `Please fill all required fields (*)`}
                </span>
              ) : null}
            </div>
            <motion.button
              initial={{ opacity: 0, visibility: "hidden" }}
              animate={{ opacity: 1, visibility: "visible" }}
              className={styles.contactButton}
              type="submit"
            >
              <span>Submit</span>
              <motion.img
                height="auto"
                width="auto"
                src={arrowRightWhite}
                alt="contact us"
              />
            </motion.button>
          </form>
        </div>
        <CircleBlur
          initialScale={0.1}
          initialOpacity={0}
          animateScale={1.8}
          animateOpacity={0.12}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundInnerCircleLeft}
        />
      </div>
    </Layout>
  );
};

export default SingleWebinarForm;
